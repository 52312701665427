*{
  margin: 0;
  padding: 0;
}

.App{
  display: flex;
  background-color: #dddbcb;
  overflow: hidden;
}

img{
  width: 30%;
  height: 100vh;
}

.RightContainer{
  background-color: white;
  width: 600px;
  height: 560px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

h1{
  font-size: 40px;
  font-weight: 600;
  color: #445A6F;
  margin: 40px 0 ;
}

button{
  width: 150px ;
  height : 40px ; 
  border-radius: 5px;
  background-color: #445A6F;
  border: 1px solid #445A6F;
  color: white;
  font-size: 20px;
  margin: 30px 0 ;
}

.qr-code-container {
  margin-top: 20px; /* Adjust the margin as needed */
}

.bottom-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #dddbcb;
  padding: 10px;
  text-align: center;
}

.role{
  color: #4e45d5;
  
  font-weight: 800;
} 
@media only screen and (max-width: 770px) {
  img {
    display: none; /* Hide the image */
  }

  .RightContainer {
    width: 100%; /* Make RightContainer full width */
    /* height: 100vh; Make RightContainer full height */
  }



    
    .bottom-div {
      position: fixed;
    }
  
}